<template>
  <div>
    <b-card title="لوحة التحكم">
      <b-card-text>Op+ Dashboard.</b-card-text>
    </b-card>

   
  </div>
</template>

<script>
import { BCard, BCardText, BLink } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardText,
    BLink,
  },
}
</script>

<style>

</style>
